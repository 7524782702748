<template>
  <diy-card :is-card="false">
    <div slot="header" class="clearfix" style="display: flex">
      <el-image
        :src="require('@/assets/images/problemList/标签@2x.png')"
        style="width: 24px; height: 24px"
      ></el-image>
      <span style="color: #00957e">{{
        $route.params.list_id && $route.params.list_id !== "0"
          ? "编辑条目"
          : "添加条目"
      }}</span>
    </div>
    <form-create
      ref="dialogListForm"
      v-model="listInfo"
      :label-width="'100px'"
      :components="childrens"
      @update="handleUpdateForm"
    >
      <template slot="triggerUpload"><span></span></template>
      <div
        style="display: inline-block"
        slot="video"
        slot-scope="{ props, fileList, prop, handTrigger, uploadFiles }"
      >
        <el-button-group style="margin-bottom: 10px">
          <template v-if="(fileList || []).length > 0">
            <el-button @click="handTrigger" size="small" type="primary">
              重新上传
            </el-button>
            <el-button
              @click="
                listInfo.video = null;
                listInfo.duration = null;
              "
              v-if="
                videoPercent == 100 ||
                (listInfo.video && listInfo.video.length > 0)
              "
              size="small"
              type="warning"
              >删除视频</el-button
            >
          </template>
          <template v-else>
            <el-button @click="handTrigger" size="small" type="primary"
              >上传视频</el-button
            >
          </template>
        </el-button-group>
        <div v-if="(props || {}).showFileList === false">
          <el-progress
            color="#00957E"
            :format="
              () => {
                return videoPercent + '%';
              }
            "
            :percentage="parseInt(videoPercent)"
            v-if="videoPercent > 0 && videoPercent < 100"
          ></el-progress>
          <video-player
            :reloadUrl="fileList[0].url"
            :autoplay="false"
            style="width: 480px; height: 270px"
            :custom-setting="false"
            @videoTime="handleCommand('videoTime', ...arguments)"
            v-if="(fileList || []).length >= 1"
          ></video-player>
        </div>
      </div>
      <div
        style="display: inline-block"
        slot="pdf"
        slot-scope="{ props, fileList, prop, handTrigger }"
      >
        <el-button-group style="margin-bottom: 10px">
          <template v-if="(fileList || []).length > 0">
            <el-button @click="handTrigger" size="small" type="primary"
              >重新上传</el-button
            >
            <el-button
              @click="listInfo.files = null"
              size="small"
              type="warning"
              >删除PDF</el-button
            >
          </template>
          <template v-else>
            <el-button @click="handTrigger" size="small" type="primary"
              >上传PDF</el-button
            >
          </template>
        </el-button-group>
        <div v-if="(props || {}).showFileList === false">
          <el-progress
            color="#00957E"
            :format="
              () => {
                return pdfPercent + '%';
              }
            "
            :percentage="parseInt(pdfPercent)"
            v-if="pdfPercent > 0 && pdfPercent < 100"
          ></el-progress>
          <el-pdf
            :src="fileList[0].url"
            style="width: 480px; height: 270px"
            v-if="(fileList || []).length >= 1"
          ></el-pdf>
        </div>
      </div>
      <template slot="after">
        <slot name="after">
          <div style="display: flex;
  justify-content: flex-end;">
            <!-- <el-button
              @click="
                listInfo.course_id && listInfo.course_id !== '0'
                  ? $handleRoute(
                      { course_id: listInfo.course_id },
                      'teachingCourseCont'
                    )
                  : $handleRoute('teachPlanCourseCont')
              "
              >取 消</el-button
            > -->
            <el-button @click="$router.back()">取 消</el-button>
            <el-button type="primary" @click="handleCommand('listSave')"
              >确 定</el-button
            >
          </div>
        </slot>
      </template>
    </form-create>
  </diy-card>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer/index.vue";
import ElPdf from "@/components/com/el/elPdf"; //视频播放

export default {
  components: { ElPdf, VideoPlayer },
  props: ["list_id"],
  data() {
    var _this = this;
    return {
      listInfo: {},
      isOnlyField: false,
      videoPercent: 0,
      pdfPercent: 0,
      selectData: [],
      childrens: [
        {
          name: "form-item",
          props: {
            prop: "unit_id",
            required: true,
            label: "章节名称",
            // disabled:this.$route.params.list_id && this.$route.params.list_id !== "0"?false:true,
            type: "select",
            data: {
              url: "/xapi/course.course/unitOptions",
              params: { ...this.$route.params },
            },
            // data: this.selectData,
          },
        },
        {
          name: "form-item",
          props: { prop: "name", required: true, label: "条目名称" },
        },
        {
          name: "form-item",
          props: {
            prop: "is_free",
            required: true,
            label: "是否免费",
            type: "radio",
            defaultValue: 0,
            data: [
              { label: "免费", value: 0 },
              { label: "付费", value: 1 },
            ],
          },
        },
        {
          name: "form-item",
          props: {
            prop: "video",
            required: true,
            label: "条目视频",
            type: "upload",
            accept: "video/*",
            showFileList: false,
            listType: "text",
            options: {
              class: "upload-box upload-video",
              // data: { is_append: 1 },
            },
            notice: "请上传16:9的视频",
            
            rules: [
              {
                required: true,
                validator: (rule, value, callback) => {
                  if (!value && !this.listInfo.files) {
                    return callback(new Error("条目视频不能为空"));
                  }
                },
              },
            ],
            beforeUpload: (e) => {
              console.log(e, "上传之前11111");
              // let oss = new OSS({
              //   region: "oss-cn-hangzhou", //地域
              //   accessKeyId: "LTAI5tCdqiuXoAgCYcfnLVfL", //你自己的key和id
              //   accessKeySecret: "RKh2ZH0Na8g9FxhIiZbLwnlyJVkKSV",
              //   bucket: "kencodingpicture", //oss上你的存储空间名称
              //   secure: true,
              //   timeout: 120000,
              //   // region: "oss-cn-beijing", //地域
              //   // accessKeyId: "LTAI5tB5C2WA6nDePnz3LDAY", //你自己的key和id
              //   // accessKeySecret: "HQgjdKAl05o8W9yIApzLxSZeTzN4Uy",
              //   // bucket: "kencoding", //oss上你的存储空间名称
              //   // secure: true,
              //   // timeout: 120000,
             
              // });
              // // console.log(oss, "ossssssssssss");
              // // console.log(oss.multipartUpload, "oss.multipartUpload");
              // // return
              // let filePath = `/kenkeding/${dayjs().format("YYYY-MM-DD-HH:mm:ss")}/${
              //   e.name
              // }`;
              // // console.log(file, "filefilefile");
              // console.log(filePath, "============");
              // console.log;
              // oss
              //   .multipartUpload(filePath, e, {
              //     progress(p) {
              //       _this.videoPercent = (p * 100).toFixed(0);
              //       console.log(_this.videoPercent,"pppppppppppppp");
              //       // 获取上传进度，上传进度为0 - 1， 1为上传100%
                  
              //     },
              //   })
              //   .then((result) => {
              //     console.log(result.res.requestUrls[0], "result");
              //     // return result.res.requestUrls[0]

              //     // 返回的url需要处理
              //     let { res } = result;
              //     // if (res.status === 200) {
              //     let url = res.requestUrls[0];
              //     let lastIndex = url.lastIndexOf("?");
              //     if (lastIndex !== -1) url = url.substr(0, lastIndex);
              //     this.listInfo.video = url;
              //     console.log(this.listInfo.video, "shipship");

              //     //   resolve(url);
              //     // } else {
              //     //   reject("");
              //     // }
              //   });
              // _this.videoPercent = 0;
            },
            onProgress: (d) => {
              // console.log(d, "dddddddddddddd");
              _this.videoPercent = (d * 100).toFixed(0);
            },
            onSuccess:(e)=>{
              this.listInfo.video=e.data.url
            }
          },
          slots: { content: "video", trigger: "triggerUpload" },
        },
        {
          name: "form-item",
          props: {
            prop: "duration",
            required: true,
            disabled: true,
            label: "视频时长",
            rules: [
              {
                required: true,
                validator: (rule, value, callback) => {
                  if (!value && !this.listInfo.files) {
                    return callback(new Error("条目视频时长不能为空"));
                  }
                },
              },
            ],
          },
        },
        {
          name: "form-item",
          props: {
            prop: "files",
            required: true,
            label: "PDF",
            type: "upload",
            accept: ".pdf",
            showFileList: false,
            listType: "text",
            options: { class: "upload-box upload-pdf" },
            notice: "条目视频和PDF其中之一必填",
            rules: [
              {
                required: true,
                validator: (rule, value, callback) => {
                  if (!value && !this.listInfo.video) {
                    return callback(new Error("PDF不能为空"));
                  }
                },
              },
            ],
            beforeUpload: (e) => {
              console.log(e, "上传之前");
              // let oss = new OSS({
              //   region: "oss-cn-hangzhou", //地域
              //   accessKeyId: "LTAI5tCdqiuXoAgCYcfnLVfL", //你自己的key和id
              //   accessKeySecret: "RKh2ZH0Na8g9FxhIiZbLwnlyJVkKSV",
              //   bucket: "kencodingpicture", //oss上你的存储空间名称
              //   secure: true,
              //   timeout: 120000,
              //   // region: "oss-cn-beijing", //地域
              //   // accessKeyId: "LTAI5tB5C2WA6nDePnz3LDAY", //你自己的key和id
              //   // accessKeySecret: "HQgjdKAl05o8W9yIApzLxSZeTzN4Uy",
              //   // bucket: "kencoding", //oss上你的存储空间名称
              //   // secure: true,
              //   // timeout: 120000,

              //   // https://kencoding.oss-cn-beijing.aliyuncs.com/kenkeding/2023/07/04/1639_169775.png
            
              // });
              // // console.log(oss, "ossssssssssss");
              // // console.log(oss.multipartUpload, "oss.multipartUpload");
              // // return
              // let filePath = `/kenkeding/${dayjs().format(
              //   "YYYY-MM-DD-HH:mm:ss"
              // )}/${e.name}`;
              // // console.log(file, "filefilefile");
              // console.log(filePath, "============");
              // oss.multipartUpload(filePath, e,{
              //     progress(p) {
              //       _this.pdfPercent = (p * 100).toFixed(0);
              //       // console.log(_this.videoPercent,"pppppppppppppp");
              //       // 获取上传进度，上传进度为0 - 1， 1为上传100%
                  
              //     },
              //   }).then((result) => {
              //     console.log(result,"resultresultresult");
              //   console.log(result.res.requestUrls[0], "result");

              //   this.listInfo.files = result.res.requestUrls[0];
              //   filePath = result.res.requestUrls[0];
               
              //   // return result.res.requestUrls[0]

              //   // 返回的url需要处理
              //   // let { res } = result;
              //   // // if (res.status === 200) {
              //   // let url = res.requestUrls[0];
              //   // let lastIndex = url.lastIndexOf("?");
              //   // if (lastIndex !== -1) url = url.substr(0, lastIndex);
              //   // console.log(url, "上传之前");
              //   // this.listInfo.files = url;
              //   //   resolve(url);
              //   // } else {
              //   //   reject("");
              //   // }
              // });
              _this.pdfPercent = 0;
            },
            onProgress: (d) => {
               _this.pdfPercent = (d * 100).toFixed(0);
              // _this.pdfPercent = d.percent * 100;
            },
             onSuccess:(e)=>{
              console.log(e,"-------------------------------eee");
              
              this.listInfo.files=e.data.url
            }
            
          },
          slots: { content: "pdf", trigger: "triggerUpload" },
        },
        {
          name: "form-item",
          props: { prop: "pass_grade", label: "通过得分", type: "number" },
        },
        {
          name: "form-item",
          props: { prop: "desc", label: "简介", type: "editor" },
        },
        {
          name: "form-item",
          props: { prop: "sort", label: "排序", type: "number" },
        },
        // {
        //   name: "form-item",
        //   props: {
        //     prop: "valid",
        //     label: "是否有效",
        //     type: "radio",
        //     defaultValue: 1,
        //     data: [
        //       { label: "有效", value: 1 },
        //       { label: "无效", value: 2 },
        //     ],
        //   },
        // },
      ],
    };
  },

  methods: {
    routeUpdate() {
      this.getDetail();
      console.log("11111111111111");
      console.log(this.list_id, "this.list_idthis.list_id");
      // this.$http
      //   .post("/xapi/course.course/unitOptions", { ...this.$route.params })
      //   .then((res) => {
      //     this.selectData = res.data;
      //     console.log(this.selectData, "this.selectData ");
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    getDetail() {
      if (this.list_id > 0) {
        this.loading = true;
        this.$http
          .post("/xapi/course.course/listInfo", { id: this.list_id })
          .then(({ data, isOnlyField }) => {
            this.listInfo = data;
            console.log(this.listInfo, "this.listInfothis.listInfo");
            this.isOnlyField = isOnlyField === true;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.listInfo = Object.assign(
          {},
          this.$route.query,
          this.$route.params
        );
        // console.log(this.listInfo, "无listid");
        console.log(this.$route.params, "this.$route.params");
      }
    },
    handleUpdateForm({ prop, value }) {
      //  this.getDetail();
      // console.log("prop" , prop , );
      // console.log("value" ,  value);
    },
    handleCommand(command, ...vars) {
      if (command === "videoTime") {
        let durations = [],
          duration = vars[0] || 0,
          hour = parseInt(duration / 60 / 60),
          m = parseInt((duration = duration - hour * 60 * 60) / 60),
          s = parseInt((duration = duration - m * 60));
        if (hour > 0) durations.push(hour < 10 ? "0" + hour : hour);
        durations.push(m < 10 ? "0" + m : m);
        durations.push(s < 10 ? "0" + s : s);
        this.listInfo = { ...this.listInfo, duration: durations.join(":") };
      }
      if (command === "listSave") {
        this.$refs.dialogListForm &&
          this.$refs.dialogListForm.validate((formData, valid, errors) => {
            if (valid) {
              this.$http
                .post("/xapi/course.course/listSave", {
                  ...this.$route.params,
                  ...formData,
                  id: this.list_id,
                })
                .then((data) => {
                  this.$handleMessage(
                    data.msg || "操作成功！",
                    "success",
                    () => {
                      if (this.$route.name.indexOf("teachPlanCourse") === 0) {
                        this.$handleRoute("teachPlanCourseCont");
                      } else {
                        this.$handleRoute("teachingCourseCont");
                      }
                    }
                  );
                });
            } else if (errors.length > 0) {
              this.$handleMessage(errors[0].message, "error");
            }
          }, true);
      }
    },
  },
};
</script>

<style scoped lang="less">
</style>