var render = function render(){var _vm=this,_c=_vm._self._c;return _c('diy-card',{attrs:{"is-card":false}},[_c('div',{staticClass:"clearfix",staticStyle:{"display":"flex"},attrs:{"slot":"header"},slot:"header"},[_c('el-image',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require('@/assets/images/problemList/标签@2x.png')}}),_c('span',{staticStyle:{"color":"#00957e"}},[_vm._v(_vm._s(_vm.$route.params.list_id && _vm.$route.params.list_id !== "0" ? "编辑条目" : "添加条目"))])],1),_c('form-create',{ref:"dialogListForm",attrs:{"label-width":'100px',"components":_vm.childrens},on:{"update":_vm.handleUpdateForm},scopedSlots:_vm._u([{key:"video",fn:function({ props, fileList, prop, handTrigger, uploadFiles }){return _c('div',{staticStyle:{"display":"inline-block"}},[_c('el-button-group',{staticStyle:{"margin-bottom":"10px"}},[((fileList || []).length > 0)?[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":handTrigger}},[_vm._v(" 重新上传 ")]),(
              _vm.videoPercent == 100 ||
              (_vm.listInfo.video && _vm.listInfo.video.length > 0)
            )?_c('el-button',{attrs:{"size":"small","type":"warning"},on:{"click":function($event){_vm.listInfo.video = null;
              _vm.listInfo.duration = null;}}},[_vm._v("删除视频")]):_vm._e()]:[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":handTrigger}},[_vm._v("上传视频")])]],2),((props || {}).showFileList === false)?_c('div',[(_vm.videoPercent > 0 && _vm.videoPercent < 100)?_c('el-progress',{attrs:{"color":"#00957E","format":() => {
              return _vm.videoPercent + '%';
            },"percentage":parseInt(_vm.videoPercent)}}):_vm._e(),((fileList || []).length >= 1)?_c('video-player',{staticStyle:{"width":"480px","height":"270px"},attrs:{"reloadUrl":fileList[0].url,"autoplay":false,"custom-setting":false},on:{"videoTime":function($event){return _vm.handleCommand('videoTime', ...arguments)}}}):_vm._e()],1):_vm._e()],1)}},{key:"pdf",fn:function({ props, fileList, prop, handTrigger }){return _c('div',{staticStyle:{"display":"inline-block"}},[_c('el-button-group',{staticStyle:{"margin-bottom":"10px"}},[((fileList || []).length > 0)?[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":handTrigger}},[_vm._v("重新上传")]),_c('el-button',{attrs:{"size":"small","type":"warning"},on:{"click":function($event){_vm.listInfo.files = null}}},[_vm._v("删除PDF")])]:[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":handTrigger}},[_vm._v("上传PDF")])]],2),((props || {}).showFileList === false)?_c('div',[(_vm.pdfPercent > 0 && _vm.pdfPercent < 100)?_c('el-progress',{attrs:{"color":"#00957E","format":() => {
              return _vm.pdfPercent + '%';
            },"percentage":parseInt(_vm.pdfPercent)}}):_vm._e(),((fileList || []).length >= 1)?_c('el-pdf',{staticStyle:{"width":"480px","height":"270px"},attrs:{"src":fileList[0].url}}):_vm._e()],1):_vm._e()],1)}}]),model:{value:(_vm.listInfo),callback:function ($$v) {_vm.listInfo=$$v},expression:"listInfo"}},[_c('template',{slot:"triggerUpload"},[_c('span')]),_c('template',{slot:"after"},[_vm._t("after",function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('el-button',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleCommand('listSave')}}},[_vm._v("确 定")])],1)]})],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }